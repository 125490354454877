import React from 'react'
import Layout from '../components/layout'
import { QueryParams, MadresPage } from '../components'

const SingleProduct = (props) => {
    return (
        <Layout>
            <MadresPage />
        </Layout>
    )
}

export default QueryParams(SingleProduct);